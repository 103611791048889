.rstm-toggle-icon-symbol {
	height: 2rem;
	line-height: 2rem;
	text-align: center;
	width: 2rem;
}

.rstm-toggle-icon {
	display: inline-block;
}

input.rstm-search {
	background-color: lightgoldenrodyellow;
	border: 1px solid lightgray;
	border-radius: 5px;
	padding: 0.5rem 1rem;
	width: 100%;
}

li.rstm-tree-item:hover,
li.rstm-tree-item.rstm-tree-item--active {
	background-color: rgba(255, 185, 189);
}

ul.rstm-tree-item-group {
	font-size: 1.125rem;
	line-height: 1.75rem;
	max-height: 20rem;
	overflow: auto;
}